import React, { useEffect, useState } from "react";
import "./Styles/App.css";
import whatsApp from "./assets/WhatsAppLogo.png";
import clear from "./assets/clear-button.png";

import { isMobile } from "react-device-detect";
import QRCode from "react-qr-code";
import ReactGA from "react-ga4";

const Launcher = ({ onClick, size = "normal" }) => {
  return (
    <div onClick={onClick} className={`msgboxxlauncher launcher-size-${size}`}>
      <img alt="whatsapp logo" src={whatsApp} />

      {size != "small" && (
        <div className="msgboxxlauncher_cta">
          <span>WhatsApp</span>
          <span>Chat with us</span>
        </div>
      )}
    </div>
  );
};

function App() {
  ReactGA.initialize("G-QKVCDW7QCW");

  const [chatOpen, setChatOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);

  const [qrValue, setQrValue] = useState("");

  const height = window.innerHeight;

  // Handle screen size changin
  function handleTabletChange(e) {
    if (e.matches) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  }
  useEffect(() => {
    if (chatOpen) {
      ReactGA.event({
        category: "ChatWindow",
        action: "WindowOpened",
      });
    } else {
      ReactGA.event({
        category: "ChatWindow",
        action: "WindowClosed",
      });
    }
  }, [chatOpen]);

  useEffect(() => {
    var msgboxxClient = window.msgboxxClient;
    if (!msgboxxClient) {
      msgboxxClient = window.omnichatClient;
      if (!msgboxxClient) {
        msgboxxClient = window.whatexClient;
      }
    }
    if (msgboxxClient) {
      const cacheKey = "WAConfig-" + msgboxxClient;
      const cachedConfig = sessionStorage.getItem(cacheKey);
      if (cachedConfig) {
        setConfig(JSON.parse(cachedConfig));
      } else {
        const url = "https://api.msgboxx.io/widget/" + msgboxxClient;
        fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(data));
            setConfig(data);
          });
      }
    } else {
      setConfig(null);
    }
    const mediaQuery = window.matchMedia("(max-width: 480px)");
    // Check inital
    if (mediaQuery.matches) {
      setFullscreen(true);
    }
    mediaQuery.addEventListener("change", handleTabletChange);

    return () => {
      mediaQuery.removeEventListener("change", handleTabletChange);
    };
  }, []);

  const handleLocationClick = (location) => {
    const isWhatsapp = location.whatsappurl.includes("wa.me") || location.whatsappurl.includes("api.whatsapp.com");

    if (isMobile || !isWhatsapp) {
      ReactGA.event({
        category: "LocationClicked",
        action: "OpeningLocation",
      });
      window.open(location.whatsappurl, "_blank");
    } else {
      ReactGA.event({
        category: "LocationClicked",
        action: "ShowingQRCode",
      });
      setQrValue(location.whatsappurl);
    }
  };

  const handleOpenWeb = () => {
    try {
      fetch(`https://api.msgboxx.io/widgetlog?to=${qrValue}&from=${window.location.href}`);
    } catch (exc) {
      console.log("failed to log, error:", exc);
    }

    ReactGA.event({
      category: "LocationClicked",
      action: "OpeningWeb",
    });
    window.open(qrValue, "_blank");
  };

  const handleGoBack = () => {
    setQrValue("");
  };

  if (config) {
    return (
      <div className="msgboxxApp">
        <div className="msgboxxContainer">
          {chatOpen === true ? (
            <div style={{ height: fullscreen ? `${height}px` : "60vh" }} className="msgboxxchat-window">
              <div className="msgboxxchat-window__header">
                {/* <img src={android} /> */}
                <p className="msgboxx-title">💬 Message us on WhatsApp</p>
                <button className="msgboxxmobile__close" onClick={() => setChatOpen(false)}>
                  <img alt="close icon" style={{ height: "30px" }} src={clear} />
                </button>
              </div>
              <div className="msgboxxchat-window__messages">
                {qrValue == "" && (
                  <div className="msgboxxchat-window__messages__message">
                    <p>{config.welcometext}</p>
                  </div>
                )}

                <div className="msgboxxchat-window__messages__options">
                  {qrValue === "" &&
                    config.locations.map((location) => {
                      return (
                        <button className="msgboxx-button" key={location.name} onClick={() => handleLocationClick(location)}>
                          {location.name}
                        </button>
                      );
                    })}

                  {qrValue !== "" && (
                    <div className="msgboxx-qr-container">
                      <QRCode className="msgboxx-qrcode" value={qrValue} size={125} />
                      <ol>
                        <li className="instructions-list">Scan this QR code with your phone</li>
                        <li className="instructions-list">Tap the link that appears</li>
                        <li className="instructions-list">Send a message to start a chat</li>
                      </ol>
                      <div className="msgboxx-qrbuttons">
                        <button className="msgboxx-button" onClick={handleGoBack}>
                          👈 Go Back
                        </button>
                        <button className="msgboxx-button" onClick={handleOpenWeb}>
                          💬 WhatsApp Web
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div onClick={() => window.open(config.reseller.url, "_blank")} rel="noreferrer" role="link" className="msgboxxchat-window__messages__cta">
                  <a alt="visit reseller" style={{ color: "black" }} rel="noreferrer" target="_blank" href={config.reseller.url}>
                    powered by
                  </a>
                  <img alt="reseller logo" style={{ maxHeight: "25px" }} src={config.reseller.logo} />
                </div>
              </div>
            </div>
          ) : null}
          <div className="msgboxxbounce">
            {chatOpen === true ? (
              <button className="msgboxxlauncher__close" onClick={() => setChatOpen(false)}>
                <img alt="close icon" style={{ height: "12px" }} src={clear} />
              </button>
            ) : (
              <Launcher onClick={() => setChatOpen(true)} size={config?.size} />
            )}
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default App;
